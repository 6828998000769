export default {
  computed: {
    colorScheme() {
      const colorSchemes = this.$store.getters.get_color_schemes;
      const theme = this.$route.query.theme;
      return this.isIframe() && theme && colorSchemes
        ? theme in colorSchemes
          ? colorSchemes[theme]
          : {}
        : {};
    },
    smallText() {
      return (
        this.isIframe() && typeof this.$route.query.small_text != "undefined"
      );
    },
    integrationOptions() {
      const defaultOptions = {
        onTheFly: false,
        numberOfHolders: 150,
        displayATA: false,
        backendLoginOverwrite: null,
        hideVisibilityButtons: false,
        forcePercentExclude: false,
      };
      if (typeof this.$route.query.pumpfun != "undefined") {
        return {
          ...defaultOptions,
          onTheFly: true,
          numberOfHolders: 20,
          displayATA: true,
          backendLoginOverwrite: "&pumpfun=true",
          hideVisibilityButtons: true,
          forcePercentExclude: true,
        };
      } else if (typeof this.$route.query.photon != "undefined") {
        return {
          ...defaultOptions,
          onTheFly: true,
          numberOfHolders: 20,
          displayATA: false,
          backendLoginOverwrite: "&photon=true",
          hideVisibilityButtons: true,
          forcePercentExclude: true,
        };
      } else if (typeof this.$route.query.trojan != "undefined") {
        return {
          ...defaultOptions,
          onTheFly: true,
          numberOfHolders: 20,
          displayATA: false,
          backendLoginOverwrite: "&trojan=true",
          hideVisibilityButtons: true,
          forcePercentExclude: true,
        };
      } else if (typeof this.$route.query.rickbot != "undefined") {
        return {
          ...defaultOptions,
          onTheFly: true,
          numberOfHolders: 20,
          displayATA: false,
          backendLoginOverwrite: "&rickbot=true",
          hideVisibilityButtons: true,
          forcePercentExclude: true,
        };
      } else if (typeof this.$route.query.bullx != "undefined") {
        return {
          ...defaultOptions,
          onTheFly: true,
          numberOfHolders: 20,
          displayATA: false,
          backendLoginOverwrite: "&bullx=true",
          hideVisibilityButtons: true,
          forcePercentExclude: true,
        };
      } else if (typeof this.$route.query.dexscreener != "undefined") {
        return {
          ...defaultOptions,
          onTheFly: true,
          numberOfHolders: 20,
          displayATA: false,
          backendLoginOverwrite: "&dexscreener=true",
          hideVisibilityButtons: true,
          forcePercentExclude: true,
        };
      } else {
        return defaultOptions;
      }
    },
  },
  methods: {
    isIframe() {
      return window.location !== window.parent.location;
    },
    updateMetaTitle(title) {
      document.title = title;
      document.querySelectorAll("meta").forEach((tag) => {
        if (
          ["og:title", "twitter:title"].includes(tag.getAttribute("property"))
        ) {
          tag.setAttribute("content", title);
        }
      });
    },
    updateMetaDescription(description) {
      document.querySelectorAll("meta").forEach((tag) => {
        if (
          ["og:description", "twitter:description"].includes(
            tag.getAttribute("property")
          ) ||
          tag.getAttribute("name") == "description"
        ) {
          tag.setAttribute("content", description);
        }
      });
    },
  },
};
